/* import __COLOCATED_TEMPLATE__ from './custom-answer.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent-2 */

import { inject as service } from '@ember/service';
import type RouterService from '@ember/routing/router-service';
import Component from '@glimmer/component';
import { type CustomAnswer } from 'embercom/lib/fin-playground/custom-answer';

interface CustomAnswerAnswerNoticeComponentSignature {
  Args: {
    customAnswer?: CustomAnswer;
  };
  Element: HTMLDivElement;
  Blocks: {
    default: [];
  };
}

export default class CustomAnswerAnswerNoticeComponent extends Component<CustomAnswerAnswerNoticeComponentSignature> {
  @service declare router: RouterService;
  @service declare appService: any;

  get customAnswerUrl(): string {
    return this.router.urlFor(
      'apps.app.automation.fin-ai-agent.custom-answers.answer.show.index',
      this.appService.app.id,
      this.args.customAnswer?.id,
    );
  }

  get customAnswerTitle(): string {
    return this.args.customAnswer?.title ?? '';
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'AiAgent::Playground::FinAnswer::AnswerNotice::CustomAnswer': typeof CustomAnswerAnswerNoticeComponent;
    'ai-agent/playground/fin-answer/answer-notice/custom-answer': typeof CustomAnswerAnswerNoticeComponent;
  }
}
