/* import __COLOCATED_TEMPLATE__ from './rating-buttons.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent-2 */

import Component from '@glimmer/component';
import { CustomerAnswerRating } from 'embercom/lib/fin-playground/question';

interface Signature {
  Args: {
    shouldDisableFeedbackButtons: boolean | undefined;
    shouldMarkAsPositive: boolean;
    customerAnswerRating: CustomerAnswerRating | null;
    onSelectRating: (rating: CustomerAnswerRating) => void;
  };
}

export default class RatingButtons extends Component<Signature> {
  CustomerAnswerRating = CustomerAnswerRating;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'AiAgent::Playground::AnswerRating::RatingButtons': typeof RatingButtons;
    'ai-agent/playground/answer-rating/rating-buttons': typeof RatingButtons;
  }
}
